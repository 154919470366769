import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, map, of} from 'rxjs';

@Injectable({providedIn: 'root'})
export class BrandService {
  private apiVersion = 'api/v1/';

  constructor(private http: HttpClient) {}

  static getPlatformTitle(): string {
    if (location.hostname.includes('wago')) {
      return 'WAGO Cloud';
    } else {
      return 'Avelon Cloud';
    }
  }

  getTitle(): Observable<string> {
    const subdomain = location.hostname.split('.')[0];
    if (location.hostname.includes('wago')) {
      return of('WAGO IoT');
    } else if (subdomain === 'iot') {
      return of('Avelon IoT');
    } else {
      return this.http
        .get<any>(this.apiVersion + 'no-auth/brands/companyName')
        .pipe(map((response: {companyName: string}) => response.companyName + ' | Avelon IoT'));
    }
  }
}
