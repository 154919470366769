import {Injectable} from '@angular/core';
import {TranslocoService} from '@jsverse/transloco';
import {EventTypes, OidcSecurityService, PublicEventsService} from 'angular-auth-oidc-client';
import {Settings} from 'luxon';
import {NgxPermissionsService} from 'ngx-permissions';
import {NEVER, Observable, retry, throwError} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {UserLocaleService} from '../../../../alcedo/src/app/core/auth/user-locale.service';
import {LocalStorageKeys} from '../../../../alcedo/src/app/shared/constants/local-storage-keys.constant';

import {UserSessionService} from '../../../../alcedo/src/app/shared/entities/auth/user-session.service';
import {CurrentUser} from '../../../../alcedo/src/app/shared/entities/user/avelon-user.interface';
import {Alcedo7User} from '../../../../alcedo/src/app/shared/entities/user/avelon-user.service';
import {CurrentUserEntity} from '../../../../alcedo/src/app/shared/entities/user/current-user.entity';
import {orderByLocale} from '../../../../alcedo/src/app/shared/services/order-by-locale.service';

@Injectable({providedIn: 'root'})
export class AuthService {
  private currentClientLoaded: number = null;
  resolvedAuth = false;

  constructor(
    private permissions: NgxPermissionsService,
    private translate: TranslocoService,
    private currentUserEntity: CurrentUserEntity,
    private oidcSecurityService: OidcSecurityService,
    private eventService: PublicEventsService
  ) {
    window.addEventListener('storage', (event: StorageEvent) => this.syncTabs(event));

    this.eventService.registerForEvents().subscribe(notification => {
      if (notification.type === EventTypes.UserDataChanged) {
        if (notification.value.userData) {
          UserSessionService.setSessionDummy();
        } else {
          UserSessionService.removeSessionDummy();
        }
      }
    });
  }

  syncTabs(event: StorageEvent): void {
    const isAccessToken = event.key === LocalStorageKeys.UserSession.sessionDummyKey;
    const isLogin = isAccessToken && !event.oldValue && event.newValue;
    const isLogout = isAccessToken && event.oldValue && !event.newValue;
    if (isLogout && Alcedo7User.currentUser) {
      UserSessionService.clearUserFromMemory();
      this.oidcSecurityService.logoff().subscribe();
    } else if (isLogin) {
      this.oidcSecurityService.authorize();
    }
  }

  loadCurrentUser(): Observable<CurrentUser> {
    let selectedClientId = UserSessionService.getSelectedClientId();
    return this.currentUserEntity.getCurrentUser({clientId: selectedClientId}).pipe(
      switchMap((response: CurrentUser) => {
        selectedClientId = selectedClientId || response.clientId;
        Alcedo7User.selectedClient = response.currentClient;
        UserSessionService.setSelectedClientId(selectedClientId);
        this.currentUserEntity.getClients().subscribe(clients => (Alcedo7User.clients = orderByLocale(clients, 'name')));
        return this.setUser(response, selectedClientId).pipe(map(() => response));
      }),
      catchError(err => {
        return this.oidcSecurityService.isAuthenticated().pipe(
          switchMap(isAuthenticated => {
            if (isAuthenticated) {
              return throwError(err);
            } else {
              this.oidcSecurityService.logoffLocal();
              UserSessionService.destroy();
              return NEVER;
            }
          })
        );
      }),
      retry({count: 1000, delay: 2000}),
      catchError(err => {
        console.error(err);
        this.oidcSecurityService.logoffLocal();
        UserSessionService.destroy();
        return NEVER;
      })
    );
  }

  setLocale(locale: string): Observable<any> {
    Settings.defaultLocale = locale;
    const language = UserLocaleService.getLanguage(locale);
    return this.translate.load(language);
  }

  private setUser(user: CurrentUser, selectedClientId: number): Observable<any> {
    if (selectedClientId) {
      this.currentClientLoaded = selectedClientId;
    } else {
      this.currentClientLoaded = user.clientId;
    }
    this.permissions.loadPermissions(user.userRole.actions as any);
    return this.setLocale(user.language);
  }
}
